<div class="row gap-2 row-cols-lg-8 row-cols-3 align-content-baseline" [ngClass]="{ 'area-container': !isSeeMore }">
	@for (area of areaList; track area) {
		<div class="col text-truncate">
			<a class="text-caption-regular fg-general-4 text-decoration-none" [routerLink]="area.reference | countyLink: selectedSection">{{ area.title }}</a>
		</div>
	}
</div>

<div class="row align-items-center gap-3" *ngIf="areaList.length > 45">
	<div class="col">
		<mat-divider></mat-divider>
	</div>

	<div class="col-auto">
		<button class="pe-0 ps-1" mat-button color="primary w-100 text-caption-regular" (click)="isSeeMore = !isSeeMore">
			{{ (isSeeMore ? 'n3091' : 'n3090') | translations }}

			<mat-icon class="ms-1" iconPositionEnd>{{ isSeeMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
		</button>
	</div>
</div>
