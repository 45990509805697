import { Injectable } from '@angular/core';
import { FiltersApi } from '@core/data-access/apis/filters.api';
import { FilterDropdownValue } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import * as topRentCounties from '../../../shared/json/counties/counties.json';

@Injectable()
export class FooterFacade {
	constructor(private readonly filtersApi: FiltersApi) {}

	private _counties$ = new BehaviorSubject<FilterDropdownValue[]>(null);

	get getCounties$(): Observable<FilterDropdownValue[]> {
		return this._counties$.asObservable();
	}

	getCounties(): FilterDropdownValue[] {
		return this._counties$.getValue();
	}

	fetchCountyList(params) {
		if (params['section'] === 'properties-for-sale') {
			this._counties$.next(topRentCounties?.data?.all);
		} else {
			this.filtersApi.getCountyList(params).subscribe((res) => {
				this._counties$.next(res?.['all']);
			});
		}
	}
}
