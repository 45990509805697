import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/data-access/services/auth.service';
import { RouteService } from '@core/services/route.service';
import { PostAdDialogComponent } from '@shared/components/post-ad-dialog/post-ad-dialog.component';
import { AbstractHeaderComponent } from '../../abstracts/header.abstract';
import { ProfileDisabledDialogComponent } from '@feature/place-ad/components/profile-disabled-dialog/profile-disabled-dialog.component';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';
import { ClientUser } from '@core/models';

@Component({
	selector: 'findqo-header-v2',
	templateUrl: './header-v2.component.html',
	styleUrls: ['./header-v2.component.scss'],
})
export class HeaderV2Component extends AbstractHeaderComponent implements OnInit {
	constructor(
		private readonly routeService: RouteService,
		private authService: AuthService,
		private readonly userFacade: MyProfileFacade
	) {
		super();
	}

	routeData$ = this.routeService.routeData$;

	goToPlaceAd(): void {
		const IS_LOGGED_IN = this.authService.user()?.accessToken;

		this.userFacade.getProfile().subscribe((profile: ClientUser) => {
			const IS_ACCOUNT_DISABLED = +profile?.isDisabled;

			if (IS_ACCOUNT_DISABLED) {
				this.dialog.open(ProfileDisabledDialogComponent, {
					panelClass: 'post-ad-dialog',
				});

				return;
			}

			if (IS_LOGGED_IN) {
				this.openPostAdDialog();
			} else {
				this.authService.openAuthDialog({ onLogin: this.openPostAdDialog.bind(this), isPostAd: true });
			}
		});
	}

	private openPostAdDialog() {
		return this.dialog.open(PostAdDialogComponent, {
			panelClass: 'post-ad-dialog',
		});
	}

	override ngOnInit(): void {
		super.ngOnInit();
	}
}
