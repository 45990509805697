import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SectionEnum } from '@core/enums/footer.enum';
import { FooterFacade } from '../../footer.facade';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomsheetComponent } from '../bottomsheet/bottomsheet.component';

type SectionType = 'rent-residential' | 'sell-residential';

@Component({
	selector: 'findqo-footer-county-list',
	templateUrl: './footer-county-list.component.html',
	styleUrl: './footer-county-list.component.scss',
})
export class FooterCountyListComponent implements OnInit {
	constructor(
		protected readonly footerFacade: FooterFacade,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly bottomsheet: MatBottomSheet
	) {}

	readonly isPlatformBrowser = isPlatformBrowser(this.platformId);
	readonly sectionEnum = SectionEnum;
	selectedSection: SectionType = this.sectionEnum.SELL_RESIDENTIAL;

	openBottomSheet(): void {
		this.bottomsheet
			.open(BottomsheetComponent, {
				data: { selectedSection: this.selectedSection },
			})
			.afterDismissed()
			.subscribe((res) => {
				if (res) {
					this.onSectionChange(res);
				}
			});
	}

	onSectionChange(reference: SectionType): void {
		this.selectedSection = reference;
		const SECTION_REFERENCE = reference === SectionEnum.RENT_RESIDENTIAL ? 'properties-for-rent' : 'properties-for-sale';
		this.fetchCounties(SECTION_REFERENCE);
	}

	fetchCounties(sectionReference: string): void {
		this.footerFacade.fetchCountyList({ withCountyArea: 1, section: sectionReference, topCounties: 12 });
	}

	ngOnInit(): void {
		if (!this.footerFacade.getCounties()) {
			this.fetchCounties('properties-for-sale');
		}
	}
}
