import { Component, Input } from '@angular/core';

@Component({
	selector: 'findqo-area-list',
	templateUrl: './area-list.component.html',
	styleUrl: './area-list.component.scss',
})
export class AreaListComponent {
	@Input() areaList;
	@Input() selectedSection;

	isSeeMore = false;
}
